<template>
  <div>
    <div>files</div>
    <div>{{ dir }}</div>
  </div>
</template>

<script>
// const fs = require('fs');

export default {
  name: 'upload-files-page',
  data() {
    return {
      files: [],
      dir: '',
    };
  },
  mounted() {
    this.dir = __dirname;
  },
};
</script>

<style></style>
